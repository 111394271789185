class discountComponent extends Component {

    static name() {
        return "discountComponent";
    }

    static componentName() {
        return "discountComponent";
    }

    getProps() {
        return  ['item', 'currentItemPrice'];
    }

    getComputed() {
        return {
            
            discountPercentage: function() {
                return 100 - Math.round((this.currentItemPrice * 100)/this.item.CrossedOutPrice);
            }
        };
    }


    getTemplate() {
        return ` <div>{{discountPercentage}}% OFF</div>`;
    }
}

discountComponent.registerComponent();